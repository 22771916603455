import { Pipe, PipeTransform } from '@angular/core';
import padStart from 'lodash-es/padStart';
import padEnd from 'lodash-es/padEnd';
import { decimalHoursToDurationParts } from '@shiftbase-com/utilities';

@Pipe({
  name: 'decimalToTime',
  pure: true,
  standalone: true,
})
export class DecimalToTimePipe implements PipeTransform {
  public transform(
    decimal: string | number | null | undefined,
    showSeconds = false,
    emptyVal: false | string = false,
    padHours = false
  ): string {
    if (!decimal) {
      return emptyVal ? emptyVal : '0:00';
    }

    if (typeof decimal === 'string') {
      if (decimal.match('^(|0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$')) {
        return decimal;
      }

      decimal = parseFloat(decimal);
    }

    const parts = decimalHoursToDurationParts(decimal);
    if (showSeconds) {
      return this.toHHMMSS(parts, padHours);
    }

    return this.toHHMM(parts, padHours);
  }

  private toHHMM(parts, padHours: boolean = false) {
    const sign = parts.sign != '-' ? '' : '-';
    let hours = parts.hours;
    if (padHours) {
      hours = this.padStart(hours);
    }
    const minutes = this.padStart(parts.minutes);

    return `${sign}${hours}:${minutes}`;
  }

  private toHHMMSS(parts, padHours: boolean = false) {
    const sign = parts.sign != '-' ? '' : '-';
    let hours = parts.hours;
    if (padHours) {
      hours = this.padStart(hours);
    }
    const minutes = this.padStart(parts.minutes);
    const seconds = this.padStart(parts.seconds);

    return `${sign}${hours}:${minutes}:${seconds}`;
  }

  private padStart(timePart: number) {
    return padStart(timePart.toString(), 2, '0');
  }

  private padEnd(timePart: number) {
    return padEnd(timePart.toString(), 2, '0');
  }

  public parse(time: string, precision = 2): string {
    const [hours, minutes, seconds] = time.split(':');

    const parsedHours = Math.abs(parseInt(hours, 10));
    const parsedMinutes = this.parseTimePart(minutes);
    const parsedSeconds = this.parseTimePart(seconds);

    let signedFactor;
    const signedHours = parseInt(hours, 10);

    if (1 / signedHours === -Infinity) {
      signedFactor = -1;
    } else {
      signedFactor = signedHours < 0 ? -1 : 1;
    }

    const total = (parsedSeconds + parsedMinutes * 60 + parsedHours * 60 * 60) * signedFactor;

    return (total / 3600).toFixed(precision);
  }

  public parseTimePart(timePart: string): number {
    if (!timePart) {
      return 0;
    }

    /*
     Only apply padding to the end when the timePart has a length of 1.
     Otherwise, 02 will be parsed to 2 and then padded at the end to 20.
      */
    if (timePart.length === 1) {
      return parseInt(this.padEnd(parseInt(timePart, 10)), 10);
    }

    return parseInt(timePart.substring(0, 2), 10);
  }
}
